import React, { useState } from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
    },
    paper: {
      padding: '20px',
      width: '30%',
      minWidth: '250px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    form: {
      width: '100%',
      marginTop: '20px',
    },
    submit: {
      margin: '20px 0 20px',
    },
  }),
);

function LoginPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const classes = useStyles();

  const handleSubmit = (e) => {
    e.preventDefault();
    window.location.href = "https://google.com"
    };

  return (
    <Grid
  container
  spacing={0}
  direction="column"
  alignItems="center"
  justifyContent="center"
  style={{ minHeight: '100vh' }}
>
    
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h5">
         wait_for_password Sign in
        </Typography>
        <form className={classes.form}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          {username.length > 0 && (
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="password"
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          )}
          {username.length > 0 && password.length > 0 && (
            <Button
              variant="contained"
              color="primary"
              className={classes.submit}
              type="submit"
              onClick={handleSubmit}
            >
              Login
            </Button>
          )}
        </form>
      </Paper>
    
    </Grid>
  );
}
export default LoginPage;
