import React from "react";

function AutomaticRedirectToLogin() {
    setTimeout(() => {
        window.location.href = "/login";
    }, 3000);

    return (
        <div>
        <h1>Redirecting...</h1>
        </div>
    );
}

export default AutomaticRedirectToLogin;


