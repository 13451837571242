import React from 'react';
import { FunctionComponent } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from './pages/LoginPage.tsx';
import AutomaticRedirectToLogin from './pages/AutomaticRedirectToLogin.tsx'

const App: FunctionComponent = () => {  
    return (
      <BrowserRouter>
            <Routes>
                <Route
                    path="/login"
                    element={ <LoginPage /> }
                />
                <Route
                    path="/"
                    element={ <LoginPage /> }
                />
                <Route
                    path="/redirect"
                    element={<AutomaticRedirectToLogin/> }
                />
            </Routes>
      </BrowserRouter>
    );
  };
  
  export default App;